<template>
    <modal
        id="reSent"
        :title="$t('modal.reSendVerification.heading')"
        type="general"
    >
        <p class="sm mb-4">
            {{ $t('modal.reSendVerification.text', { email: verificationEmail }) }}
        </p>
        <div class="member d-flex">
            <p class="sm">
                {{ $t('modal.reSendVerification.problemsText') }}
            </p>
            <span
                class="re-sent"
                @click="closeAndRedirect"
            >
                {{ $t('modal.reSendVerification.contactUsText') }}
            </span>
        </div>
    </modal>
</template>

<script>
    import { mapMutations, mapState } from 'vuex';

    import Modal from './Modal.vue';
    export default {
        name: 'ReSentVarificationEmailModal',
        components: {
            Modal,
        },
        computed: {
            ...mapState('auth', ['verificationEmail']),
        },
        methods: {
            ...mapMutations('general', ['setModal']),
            closeModal() {
                this.setModal({
                    show: false,
                    type: 'reSent',
                });
            },
            closeAndRedirect() {
                this.closeModal();
                this.$router.push({
                    name: 'Pagina',
                    params: { slug: this.$t('page.contact.slug') },
                }).catch(() => {
                    //
                });
            },
        },
    };
</script>
